import { createTheme } from 'office-ui-fabric-react';

const themes = {
    riparte: {
        greyBgTheme: createTheme({
            palette: {
              "themePrimary": "#0078d4",//"#0078d4",
              "themePrimaryAlt": "#eff6fc",//"#0078d4",
              "themeLighter": "#deecf9",
              "themeLight": "#c7e0f4",
              "themeTertiary": "#eeeeee",
              "themeSecondary": "#00376e",
              "themeDarkAlt": "#ffffff",
              "themeDark": "#005a9e",
              "themeDarker": "#004578",
              "neutralLighterAlt": "#ffffff",
              "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
              "neutralLight": "#252525",
              "neutralQuaternaryAlt": "#2f2f2f",
              "neutralQuaternary": "#373737",
              "neutralTertiaryAlt": "#595959",
              "neutralTertiary": "#c8c8c8",
              "neutralSecondary": "#d0d0d0",
              "neutralPrimaryAlt": "#dadada",
              "neutralPrimary": "#000000",
              "neutralDark": "#f4f4f4",
              "black": "#f8f8f8",
              "white": "#cccccc",
              "primaryBackground": "#ffffff",
              "primaryText": "#000000",
              "bodyBackground": "#ffffff",
              "bodyText": "#000000",
              "disabledBackground": "#151515",
              "disabledText": "#595959"
            }
        }),
        transparentBgTheme: createTheme({
            palette: {
              "themePrimary": "transparent",//"#0078d4",
              "themePrimaryAlt": "#eff6fc",//"#0078d4",
              "themeLighter": "#deecf9",
              "themeLight": "#c7e0f4",
              "themeTertiary": "#eeeeee",
              "themeSecondary": "#00376e",
              "themeDarkAlt": "#ffffff",
              "themeDark": "#005a9e",
              "themeDarker": "#004578",
              "neutralLighterAlt": "#ffffff",
              "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
              "neutralLight": "#252525",
              "neutralQuaternaryAlt": "#2f2f2f",
              "neutralQuaternary": "#373737",
              "neutralTertiaryAlt": "#595959",
              "neutralTertiary": "#c8c8c8",
              "neutralSecondary": "#d0d0d0",
              "neutralPrimaryAlt": "#dadada",
              "neutralPrimary": "#000000",
              "neutralDark": "#f4f4f4",
              "black": "#f8f8f8",
              "white": "transparent",
              "primaryBackground": "#ffffff",
              "primaryText": "#000000",
              "bodyBackground": "#ffffff",
              "bodyText": "#000000",
              "disabledBackground": "#151515",
              "disabledText": "#595959"
            }
        }),
        darkTheme: createTheme({
            palette: {
              "themePrimary": "#eeeeee",//"#0078d4",
              "themePrimaryAlt": "#eeeeee",//"#0078d4",
              "themeLighter": "#deecf9",
              "themeLight": "#c7e0f4",
              "themeTertiary": "#eeeeee",
              "themeSecondary": "#2b88d8",
              "themeDarkAlt": "#ffffff",
              "themeDark": "#005a9e",
              "themeDarker": "#004578",
              "neutralLighterAlt": "#0b0b0b",
              "neutralLighter": "#333333", //usato anche per lo sfondo dei pulsanti active
              "neutralLight": "#252525",
              "neutralQuaternaryAlt": "#2f2f2f",
              "neutralQuaternary": "#373737",
              "neutralTertiaryAlt": "#595959",
              "neutralTertiary": "#c8c8c8",
              "neutralSecondary": "#d0d0d0",
              "neutralPrimaryAlt": "#dadada",
              "neutralPrimary": "#ffffff",
              "neutralDark": "#f4f4f4",
              "black": "#f8f8f8",
              "white": "#000000",
              "primaryBackground": "#000000",
              "primaryText": "#ffffff",
              "bodyBackground": "#000000",
              "bodyText": "#ffffff",
              "disabledBackground": "#151515",
              "disabledText": "#595959"
            }
        }),
        menuBarTheme: createTheme({
            palette: {
              themePrimary: '#2a376f',
              themeLighterAlt: '#2a376f',
              themeLighter: '#2a376f',
              themeLight: '#2a376f',
              themeTertiary: '#2a376f',
              themeSecondary: 'white',
              themeDarkAlt: '#2a376f',
              themeDark: '#2a376f',
              themeDarker: '#2a376f',
              neutralLighterAlt: '#2a376f',
              neutralLighter: '#2a376f',
              neutralLight: '#2a376f',
              neutralQuaternaryAlt: '#2a376f',
              neutralQuaternary: '#2a376f',
              neutralTertiaryAlt: '#2a376f',
              neutralTertiary: '#2a376f',
              neutralSecondary: '#2a376f',
              neutralPrimaryAlt: '#2a376f',
              neutralPrimary: '#2a376f',
              neutralDark: '#2a376f',
              black: '#2a376f',
              white: '#2a376f',
            }
        }),
        navBarTheme: createTheme({
            palette: {
              themePrimary: '#ffffff',
              themeLighterAlt: '#767676',
              themeLighter: '#a6a6a6',
              themeLight: '#c8c8c8',
              themeTertiary: '#d0d0d0',
              themeSecondary: '#dadada',
              themeDarkAlt: '#eaeaea',
              themeDark: '#f4f4f4',
              themeDarker: '#f8f8f8',
              neutralLighterAlt: '#3674aa',
              neutralLighter: '#3c78ad',
              neutralLight: '#4680b3',
              neutralQuaternaryAlt: '#4d85b6',
              neutralQuaternary: '#5289b9',
              neutralTertiaryAlt: '#6a9ac5',
              neutralTertiary: '#383939',
              neutralSecondary: 'white',
              neutralPrimaryAlt: '#a3a8a8',
              neutralPrimary: 'white',
              neutralDark: 'white',
              black: '#d8dbdb',
              white: '#00376e',
            }
        }),
        userBarTheme: createTheme({
            palette: {
              themePrimary: '#ffffff',
              themeLighterAlt: '#767676',
              themeLighter: '#a6a6a6',
              themeLight: '#c8c8c8',
              themeTertiary: '#d0d0d0',
              themeSecondary: '#dadada',
              themeDarkAlt: '#eaeaea',
              themeDark: '#f4f4f4',
              themeDarker: '#f8f8f8',
              neutralLighterAlt: '#3674aa',
              neutralLighter: '#3c78ad',
              neutralLight: '#4680b3',
              neutralQuaternaryAlt: '#4d85b6',
              neutralQuaternary: '#5289b9',
              neutralTertiaryAlt: '#6a9ac5',
              neutralTertiary: '#c8c8c8',
              neutralSecondary: '#d0d0d0',
              neutralPrimaryAlt: '#dadada',
              neutralPrimary: '#ffffff',
              neutralDark: '#f4f4f4',
              black: '#f8f8f8',
              white: '#306ea6',
            }
        })
    },
    carlink: {
        greyBgTheme: createTheme({
            palette: {
              "themePrimary": "#0078d4",//"#0078d4",
              "themePrimaryAlt": "#eff6fc",//"#0078d4",
              "themeLighter": "#deecf9",
              "themeLight": "#c7e0f4",
              "themeTertiary": "#eeeeee",
              "themeSecondary": "#00376e",
              "themeDarkAlt": "#ffffff",
              "themeDark": "#005a9e",
              "themeDarker": "#004578",
              "neutralLighterAlt": "#ffffff",
              "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
              "neutralLight": "#252525",
              "neutralQuaternaryAlt": "#2f2f2f",
              "neutralQuaternary": "#373737",
              "neutralTertiaryAlt": "#595959",
              "neutralTertiary": "#c8c8c8",
              "neutralSecondary": "#d0d0d0",
              "neutralPrimaryAlt": "#dadada",
              "neutralPrimary": "#000000",
              "neutralDark": "#f4f4f4",
              "black": "#f8f8f8",
              "white": "#cccccc",
              "primaryBackground": "#ffffff",
              "primaryText": "#000000",
              "bodyBackground": "#ffffff",
              "bodyText": "#000000",
              "disabledBackground": "#151515",
              "disabledText": "#595959"
            }
        }),
        transparentBgTheme: createTheme({
            palette: {
              "themePrimary": "transparent",//"#0078d4",
              "themePrimaryAlt": "#eff6fc",//"#0078d4",
              "themeLighter": "#deecf9",
              "themeLight": "#c7e0f4",
              "themeTertiary": "#eeeeee",
              "themeSecondary": "#00376e",
              "themeDarkAlt": "#ffffff",
              "themeDark": "#005a9e",
              "themeDarker": "#004578",
              "neutralLighterAlt": "#ffffff",
              "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
              "neutralLight": "#252525",
              "neutralQuaternaryAlt": "#2f2f2f",
              "neutralQuaternary": "#373737",
              "neutralTertiaryAlt": "#595959",
              "neutralTertiary": "#c8c8c8",
              "neutralSecondary": "#d0d0d0",
              "neutralPrimaryAlt": "#dadada",
              "neutralPrimary": "#000000",
              "neutralDark": "#f4f4f4",
              "black": "#f8f8f8",
              "white": "transparent",
              "primaryBackground": "#ffffff",
              "primaryText": "#000000",
              "bodyBackground": "#ffffff",
              "bodyText": "#000000",
              "disabledBackground": "#151515",
              "disabledText": "#595959"
            }
        }),
        darkTheme: createTheme({
            palette: {
              "themePrimary": "#eeeeee",//"#0078d4",
              "themePrimaryAlt": "#eeeeee",//"#0078d4",
              "themeLighter": "#deecf9",
              "themeLight": "#c7e0f4",
              "themeTertiary": "#eeeeee",
              "themeSecondary": "#2b88d8",
              "themeDarkAlt": "#ffffff",
              "themeDark": "#005a9e",
              "themeDarker": "#004578",
              "neutralLighterAlt": "#0b0b0b",
              "neutralLighter": "#333333", //usato anche per lo sfondo dei pulsanti active
              "neutralLight": "#252525",
              "neutralQuaternaryAlt": "#2f2f2f",
              "neutralQuaternary": "#373737",
              "neutralTertiaryAlt": "#595959",
              "neutralTertiary": "#c8c8c8",
              "neutralSecondary": "#d0d0d0",
              "neutralPrimaryAlt": "#dadada",
              "neutralPrimary": "#ffffff",
              "neutralDark": "#f4f4f4",
              "black": "#f8f8f8",
              "white": "#000000",
              "primaryBackground": "#000000",
              "primaryText": "#ffffff",
              "bodyBackground": "#000000",
              "bodyText": "#ffffff",
              "disabledBackground": "#151515",
              "disabledText": "#595959"
            }
        }),
        menuBarTheme: createTheme({
            palette: {
              themePrimary: '#ef6322',
              themeLighterAlt: '#ef6322',
              themeLighter: '#ef6322',
              themeLight: '#ef6322',
              themeTertiary: '#ef6322',
              themeSecondary: 'white',
              themeDarkAlt: '#ef6322',
              themeDark: '#ef6322',
              themeDarker: '#ef6322',
              neutralLighterAlt: '#ef6322',
              neutralLighter: '#ef6322',
              neutralLight: '#ef6322',
              neutralQuaternaryAlt: '#ef6322',
              neutralQuaternary: '#ef6322',
              neutralTertiaryAlt: '#ef6322',
              neutralTertiary: '#ef6322',
              neutralSecondary: '#ef6322',
              neutralPrimaryAlt: '#ef6322',
              neutralPrimary: '#ef6322',
              neutralDark: '#ef6322',
              black: '#ef6322',
              white: '#ef6322',
            }
        }),
        navBarTheme: createTheme({
            palette: {
              themePrimary: '#ffffff',
              themeLighterAlt: '#767676',
              themeLighter: '#a6a6a6',
              themeLight: '#c8c8c8',
              themeTertiary: '#d0d0d0',
              themeSecondary: '#dadada',
              themeDarkAlt: '#eaeaea',
              themeDark: '#f4f4f4',
              themeDarker: '#f8f8f8',
              neutralLighterAlt: '#3674aa',
              neutralLighter: '#3c78ad',
              neutralLight: '#4680b3',
              neutralQuaternaryAlt: '#4d85b6',
              neutralQuaternary: '#5289b9',
              neutralTertiaryAlt: '#6a9ac5',
              neutralTertiary: '#383939',
              neutralSecondary: 'white',
              neutralPrimaryAlt: '#a3a8a8',
              neutralPrimary: 'white',
              neutralDark: 'white',
              black: '#d8dbdb',
              white: '#00376e',
            }
        }),
        userBarTheme: createTheme({
            palette: {
              themePrimary: '#ffffff',
              themeLighterAlt: '#767676',
              themeLighter: '#a6a6a6',
              themeLight: '#c8c8c8',
              themeTertiary: '#d0d0d0',
              themeSecondary: '#dadada',
              themeDarkAlt: '#eaeaea',
              themeDark: '#f4f4f4',
              themeDarker: '#f8f8f8',
              neutralLighterAlt: '#3674aa',
              neutralLighter: '#3c78ad',
              neutralLight: '#4680b3',
              neutralQuaternaryAlt: '#4d85b6',
              neutralQuaternary: '#5289b9',
              neutralTertiaryAlt: '#6a9ac5',
              neutralTertiary: '#c8c8c8',
              neutralSecondary: '#d0d0d0',
              neutralPrimaryAlt: '#dadada',
              neutralPrimary: '#ffffff',
              neutralDark: '#f4f4f4',
              black: '#f8f8f8',
              white: '#306ea6',
            }
        })
    }
}

export default themes;