//import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store-config';
import { ConnectedRouter } from "connected-react-router";
import Spinner from './core/UI/Spinner/Spinner';
import history from './core/store/history';
import configuration from './config';

import './index.scss';
import './riportalGenericForm.scss';
import './riportalDataTable.scss';
import './riportalModal.scss';
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!style-loader!css-loader!react-datepicker/dist/react-datepicker-cssmodules.css';
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!style-loader!css-loader!office-ui-fabric-react/dist/css/fabric.min.css';

//import registerServiceWorker from './registerServiceWorker';
import { loadTheme } from 'office-ui-fabric-react';
import { initializeIcons } from '@uifabric/icons';
import { setupSessionInterval } from './core/axios-wagx-web';
import AppLoader from './core/components/AppLoader/AppLoader';
import { unregister } from './core/registerServiceWorker';
import { registerIcons } from '@uifabric/styling';
const App = React.lazy(() => import('./App'));

loadTheme({
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#c2c2c2',
    neutralSecondary: '#333333', // si usa sui testi delle tabelle
    neutralPrimaryAlt: '#4b4b4b',
    neutralPrimary: '#333333',
    neutralDark: '#272727',
    black: '#1d1d1d',
    white: '#ffffff'
  }
});

const customInitializeIcons = (baseUrl, options) => {
  var subset = {
    style: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontStyle: 'normal',
      fontWeight: 'normal',
      speak: 'none'
    },
    fontFace: {
      fontFamily: "\"FabricMDL2Icons-custom\"",
      src: "url('" + baseUrl + "fabric-icons-custom.woff') format('woff')"
    },
    icons: {
      ReportWarning: '\uF569',
      WagxDoubleCheck: '\uF78C',
      ShieldAlert: '\uF7D7',
      DetectorLogo: '\uF78F',
      Factory: '\uE60E',
      // aggiungere qui icone che non si vedono, riscaricare il file delle icone aggiungendo quelle già presenti qui e sostituirlo.
      // ricompilare completamente con build-all dato che le icone vengono lette dalla porta 8090 e non dalla 3000
    }
  };
  registerIcons(subset, options);
}

// Register icons and pull the fonts from local
initializeIcons(configuration.baseURL + "/fonts/");
customInitializeIcons(configuration.baseURL + "/fonts/");

// SESSION TIMEOUT INTERVAL CHECK
setupSessionInterval();

// X CONSOLE LOG SENZA DEBUG
window.store = store;
const app = (
  <Provider store={store}>
    <PersistGate loading={<Spinner />} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Suspense fallback={<AppLoader />}>
          <App />
        </Suspense>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
unregister();
