import themes from './app/customizations/themes';
import { createTheme } from "@material-ui/core";

const config = {
  siteURL: process.env.REACT_APP_SITE_URL,
  baseURL: (process.env.REACT_APP_HOSTNAME != null ? process.env.REACT_APP_HOSTNAME : window.location.protocol + "//" + window.location.hostname + ":" + window.location.port) + process.env.REACT_APP_BASE_URL,
  BASE_PATH_NAME: process.env.REACT_APP_BASE_PATH_NAME,
  themes: themes[process.env.REACT_APP_SASS_ENV]
}

export const DEFAULT_MATERIAL_THEME = createTheme({
  palette: {
    primary: { light: "#b7cb47", main: "#a5be19", dark: "#738511" }
  }
});


export const BASE_PATH_NAME = process.env.REACT_APP_BASE_PATH_NAME;
export const BASE_APP_NAME = process.env.REACT_APP_BASE_NAME;

window.wagxBroadcastChannel = new BroadcastChannel(process.env.REACT_APP_SASS_ENV+"BroadcastChannel");
window.wagxBroadcastChannelSendToAll = new BroadcastChannel(process.env.REACT_APP_SASS_ENV+"BroadcastChannel");
window.APP_CONFIG = config;
export default config;